/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import { CookieConsent } from 'cookieconsent';
import 'jquery';
import './modernizr-custom.js';

import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.modal.js';
import Cookies from 'js-cookie/src/js.cookie.js';

$(async function () {

	// Cookie consent
	//var fireGtmEvent = function (status) {
	//	var dataLayer = (window.dataLayer || (window.dataLayer = []));
	//	dataLayer.push({
	//		'CookieConsent': status
	//	});

	//	if (this.hasConsented()) {
	//		dataLayer.push({
	//			'event': 'CookieConsent'
	//		});
	//	}
	//};

	//cookieconsent.initialise({
	//	container: document.getElementById("wrapper"),
	//	palette: {
	//		popup: { background: "#fdca00", text: "#003b6a" },
	//		button: { background: "#003b6a" }
	//	},
	//	revokable: true,
	//	onInitialise: fireGtmEvent,
	//	onStatusChange: fireGtmEvent,
	//	law: {
	//		regionalLaw: false
	//	},
	//	location: false,
	//	type: "opt-out",
	//	content: $.extend(
	//		{ href: $('html').data('cookie-consent-legal-link') },
	//		function () {
	//			var language = $('html').attr('lang');
	//			if (language === null) {
	//				language = "en";
	//			}

	//			var cookieConsentContent = require('./cookie-consent.json');
	//			return cookieConsentContent[language];
	//		}.call())
	//});

	// Notification cookie
	if (!Cookies.get('notification-read')) {
		Cookies.set('notification-read', 'true');
		var cookieConsentContent = require('./cookie-consent.json');
		var language = $('html').attr('lang');

		var content = cookieConsentContent[language].message;
		var button = '<a href="' + $('html').data('cookie-consent-legal-link') + '"> ' + cookieConsentContent[language].link + ' </a>';

		var notification = $('<div class="notification" style=""></div>');
		var notificationText = $('<div class="notification__wrapper text"><div class="container"><div class="text"><p>' + content + ' ' + button + '</p></div></div></div>').appendTo(notification);
		$('<a class="notification__close">close</a>').click(function () { notification.fadeOut(300, function () { $(this).remove(); }); }).appendTo(notificationText);

		notification.appendTo(document.body);
	}

	// Slideshow header
	$('.slider .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		new Swiper(this, {
			grabCursor: true,
			loop: true,
			slidesPerView: 3,
			spaceBetween: 25,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			navigation: {
				nextEl: '.slider-button-next',
				prevEl: '.slider-button-prev'
			},
			breakpoints: {
				765: {
					slidesPerView: 1
				},
				1200: {
					slidesPerView: 2
				}
			},
			on: {
				init: function () {
					async function matchHeights() {
						await import('jquery-match-height');

						$('.project-label-slide__content').matchHeight({
							property: 'height'
						});
					}

					if ($('.project-label-slide__content')[0]) {
						matchHeights();
					}
				}
			}
		});
	});

	$('.hero-siwper.swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		new Swiper(this, {
			grabCursor: true,
			loop: true,
			effect: 'fade',
			slidesPerView: 1,
			autoplay: {
				delay: 3000
			},
			speed: 1500
		});
	});

	// Aniversary slider
	$('.anniversary-slider .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		new Swiper(this, {
			grabCursor: true,
			loop: true,
			slidesPerView: 3,
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			breakpoints: {
				768: {
					slidesPerView: 1
				},
				1024: {
					slidesPerView: 2
				}
			},
			on: {
				init: function () {
					async function matchHeights() {
						await import('jquery-match-height');

						$('.anniversary-slider-item').matchHeight({
							property: 'height'
						});
					}

					if ($('.anniversary-slider-item')[0]) {
						matchHeights();
					}
				}
			}
		});
	});

	// Projects
	$('.project-table').each(async function () {
		await import("table-to-json");

		var $table = $(this),
			$map = $table.parent().siblings(),
			tableData = $table.tableToJSON(),
			buttonText = $table.data('button-text');

		if ($map.length) {
			var apiKey = $map.data('google-key');

			const loadGoogleMapsApi = (await import('load-google-maps-api')).default;
			const googleMaps = await loadGoogleMapsApi({
				key: apiKey
			});

			var map = new googleMaps.Map($map[0], {
				zoom: 17,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				mapTypeId: 'satellite'
			});

			var CreateMarker = function (latLng, data) {
				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: GetMarkerIcon(data)
				});
				return marker;
			};

			var GetMarkerIcon = function (data) {
				return {
					url: data['type'] === "Kubo" ? require('../images/pointer-project-kubo.png').default : require('../images/pointer-project-ultra-clima.png').default,
					scaledSize: new google.maps.Size(51, 43),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(16, 54)
				};
			};

			var CreateInfoWindow = function (marker, data) {
				var contentString = '<div class="info-window">';
				if (data['name'].length) {
					contentString += "<h2>" + data['name'] + "</h2>";
				}
				if (data['country'].length) {
					contentString += "<span>" + data['country'] + "</span>";
				}
				if (data['size'].length || data['product'].length) {
					contentString += "<span>";

					if (data['size'].length) {
						contentString += data['size'];

						if (data['product'].length) {
							contentString += " - " + data['product'];
						}
					}
					else if (data['product'].length) {
						contentString += data['product'];
					}

					contentString += "</span>";
				}

				if (data['url'].length) {
					contentString += '<a class="button" href="' + data['url'] + '">' + buttonText + '</a>';
				}
				contentString += '</div>';
				var infowindow = new google.maps.InfoWindow({
					content: contentString
				});
				marker.addListener('click', function () {
					infowindow.open(map, marker);
				});
			};

			// Create markers and info windows
			var bounds = new google.maps.LatLngBounds();

			for (var i = 0; i < tableData.length; i++) {
				var latLng = tableData[i]['location'].split(',');
				var marker = CreateMarker(latLng, tableData[i]);
				CreateInfoWindow(marker, tableData[i]);
				bounds.extend(marker.position);
			}

			map.fitBounds(bounds);
		}

		$table.hide();
	});

	// Gallery
	$('.image-gallery').each(function () {
		var element = $(this);
		var images = element.find('.gallery-item');
		var projectText = element.data('video-text');

		var RenderBigImage = function (item) {
			if (!item.hasClass('-video')) {
				return $('<img width="900" height="600" alt="" src="' + item.data('image-big') + '" />');
			}
			else {
				return $('<a rel="lightbox" href="' + item.data('video-url') + '" class="content-block__video"><h2 class="title -shadow -center -video">' + projectText + '</h2><img width="900" height="600" alt="" src="' + item.data('image-big') + '" /></a>');
			}
		};

		if (images.length) {
			var imageBig = $('<div class="image-gallery__big"></div>');
			imageBig.html(RenderBigImage($(images[0]))).insertBefore(element);

			images.each(function () {
				$(this).on('click', function () {
					imageBig.html(RenderBigImage($(this)));
				});
			});
		}
	});

	// Masonry
	$('.masonry').each(async function () {
		const Masonry = (await import(/* webpackChunkName: "masonry-layout" */ 'masonry-layout')).default;

		new Masonry(this, {
			percentPosition: true
		});
	});

	// Responsive menu
	$('header').each(function () {
		var $responsiveNav = $('.responsive-nav');

		var $toggler = $('.toggler').click(function () {
			$responsiveNav.toggleClass('-collapsed');
			$toggler.toggleClass('is-active');
		});
	});

	// Dropdown 
	$(".dropdown").each(async function () {
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");

		// Fix for link in the dropdown part
		$(this).find('a').on('click', function (event) {
			event.stopPropagation();
		});
	});

	if ("ontouchstart" in document.documentElement) {
		// Menu open
		var menuItems = $('.nav-main .sub');
		var languageSelectors = $('.languages');

		menuItems.each(function () {
			var menuItem = $(this);
			var menuLink = menuItem.find('> a');

			menuLink.on('click', function (event) {
				if (!menuItem.hasClass('open')) {
					event.preventDefault();
					menuItem.addClass('open');
					return false;
				}
				else {
					event.stopPropagation();
					menuItem.removeClass('open');
				}

			});

			menuItem.find('li a').on('click', function (event) {
				event.stopPropagation();
			});
		});

		languageSelectors.each(function () {

			$(this).on('click', function (event) {
				event.preventDefault();
				$(this).addClass('open');
				return false;
			});

			$(this).find('li a').on('click', function (event) {
				event.stopPropagation();
			});
		});

		$('body').on('click', function () {
			menuItems.removeClass('open');
			languageSelectors.removeClass('open');
		});
	}

	$('.content-block').on("click", ".overview__button", async function (event) {
		event.preventDefault();

		var overview = $('.overview');
		if (overview.length) {
			await import(/* webpackChunkName: "waypoint" */ 'waypoints/lib/jquery.waypoints.js');
			await import(/* webpackChunkName: "waypoint" */ 'waypoints/lib/shortcuts/infinite.js');

			var infinite = new Waypoint.Infinite({
				element: overview[0],
				container: overview,
				items: '.overview__column',
				more: 'a[rel=next]',
				offset: function () {
					return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
				},
				onAfterPageLoad: function (items) {
					const speed = 40, duration = 120;
					items.css('opacity', 0).each(function (i) {
						$(this).delay(speed * i).fadeTo(duration, 1);
					});
				}
			});

			$(this).find('.overview__button').hide();
		}
	});

	// Toastr
	$('.toastr').each(async function () {
		var toastr = (await import('toastr')).default;
		var Cookies = (await import('js-cookie')).default;

		var toastrDiv = $(this);
		var id = toastrDiv.data('id');
		var url = toastrDiv.data('url');
		var title = toastrDiv.data('title');
		var shortdescription = toastrDiv.data('shortdescription');
		var date = toastrDiv.data('date');
		var button = toastrDiv.data('button');

		var cookieName = 'toast',
			ignoredIds = Cookies.get(cookieName),
			ignoreId = function (id) {
				if (ignoredIds) ignoredIds += ',' + id;
				else ignoredIds = id;

				Cookies.set(cookieName, ignoredIds);
			};

		if (!ignoredIds || !ignoredIds.includes(id)) {
			// Set options
			toastr.options = {
				progressBar: true,
				closeButton: true,
				timeOut: 10000,
				extendedTimeOut: 10000,
				showDuration: 0,
				target: 'header',
				iconClasses: {
					info: 'toast-item'
				},
				titleClass: 'toast__content',
				messageClass: 'toast__date'
			};

			toastr.info('', '<h2>' + title + '</h2> <p>' + shortdescription + '<strong>' + date + '</strong> <a class="button" href="' + url + '">' + button + '</a> </p>', {
				onHidden: function () {
					// Add id to cookie
					ignoreId(id);
				}
			}).on({
				click: function () {
					// Add id to cookie and go to URL
					ignoreId(id);
					document.location = url;
				}
			});
		}
	});

	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('.pylot picture').each(function () {
			var element = $(this);
			var image = element.find('img');
			var parent = element.parent();

			parent.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'bottom left' });
			element.remove();
		});
	}

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).find('[rel=lightbox][data-html]').removeAttr('href');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});